<template>
  <div class="container">
    <div class="demand-main" v-if="demandData != null">
      <!-- <div class="breadcrumb" v-if="demandData.c_id != 0">{{ demandData.parent_category && demandData.parent_category !== '' ? demandData.parent_category + ' > ' : '' }}{{ demandData.category }}</div> -->
      <div class="breadcrumb" v-if="demandData.c_id != 0">{{ demandData.parent_category ? demandData.parent_category : '' }}{{ demandData.parent_category ? ' > ' +  demandData.category : demandData.category }}</div>
      <!-- <div class="title">{{ demandData.title }}</div> -->
      <div class="detail-box">
        <div class="detail-title">{{ demandData.title }}</div>
      </div>
      <div class="detail-box">
        <!-- <div class="detail-title">详情</div> -->
        <!-- <div class="detail">{{ demandData.detail }}</div> -->
        <div class="detail" v-html="detail"></div>
      </div>
      <div class="info">
        <!-- <div class="cover-box">
          <viewer>
            <img class="cover" :src="demandData.image !== '' ? demandData.image : nopic" alt="">
          </viewer>
        </div> -->
        <div class="info-r">
          <p class="line">
            <span class="label">发布人：</span>
            <span class="value">{{ demandData.real_name }}</span>
          </p>
          <p class="line">
            <span class="label">联系电话：</span>
            <span class="value">{{ demandData.mobile }}</span>
          </p>
          <p class="line">
            <span class="label">地区：</span>
            <span class="value" v-if="demandData.city_id !== null">{{ demandData.province }} - {{ demandData.city }}</span>
            <span class="value" v-else>全国</span>
          </p>
          <!-- <p class="line">
            <span class="label">联系人：</span>
            <span class="value">{{ demandData.contact }}</span>
          </p>
          <p class="line">
            <span class="label">联系电话：</span>
            <span class="value">{{ demandData.phone }}</span>
          </p> -->
          <p class="line">
            <span class="label">详细地址：</span>
            <span class="value">{{ demandData.address }}</span>
          </p>
          <p class="line">
            <span class="label">发布时间：</span>
            <span class="value">{{ demandData.create_date }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="not-found" v-else>{{ loading ? '加载中...' : '未找到供需信息！' }}</div>
    <!-- <iframe id="preview_frame" class="detail-frame" runat="server" frameborder="no" border="0" marginwidth="0" marginheight="0" allowtransparency="yes"></iframe> -->
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import { getDemand } from '@/api/demand.js'

export default {
  name: 'DemandDetail',
  components: {
  },
  data() {
    return {
      loading: true,
      nopic: require('@/assets/images/nopic.png'),

      id: null,
      demandData: null,
      detail: ''
    }
  },
  computed: {
    // ...mapGetters(['hasLogin']),
  },
  mounted() {
    /* // 设置预览的 iframe
    const doc =
        document.getElementById('preview_frame').contentDocument ||
        document.frames.preview_frame.document

    // doc.body.style = 'margin:20px;'

    // 设置基地址
    const base = document.createElement('base')
    base.href = this.$config.baseUrl()
    doc.head.appendChild(base)

    this.htmlBase = base
    this.htmlDoc = doc
    this.htmlDoc.body.innerHTML = null */
    this.initData()
  },
  methods: {
    async initData() {
      this.id = this.$route.query.id
      console.log(this.$route)
      await this.getDemandData()
    },
    async getDemandData() {
      try {
        const params = { id: this.id }
        const res = await getDemand(params)
        this.loading = false
        this.demandData = res.content.row
        this.detail = this.demandData.detail
        // this.detail = this.demandData.detail.replace(/src="/gi, 'src="' + this.$config.baseUrl() + '/')
        // this.htmlDoc.body.innerHTML = this.demandData.detail
        console.log(this.demandData)
      } catch (error) {
        this.$Message.error(error.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .container {
    background-color: #fff;
    min-height: calc(100vh - 64px);
    padding-bottom: 50px;
  }
  .demand-main {

    .breadcrumb {
      padding-top: 14px;
      color: #888;
      font-size: 12px
    }
    .title {
      font-size: 20px;
      color: #333;
      padding-top: 16px;
    }
    .info {
      display: flex;
      padding-top: 20px;

      .cover-box {
        width: 500px;
        height: 375px;
        border: 1px solid #f0f0f0;

        .cover {
          width: 100%;
          height: 100%;
        }
      }
      .info-r {
        padding-left: 10px;

        .line {
          display: flex;
          padding: 4px 0;
          font-size: 16px;

          .label {
            width: 100px;
            text-align: right;
            color: #999;
          }
          .value {
            padding-left: 30px;
          }
        }
      }
    }
    .detail-box {
      padding-top: 20px;

      .detail-title {
        position: relative;
        padding-left: 10px;
        font-size: 20px;
        color: #333;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: #369;
        }
      }
      .detail {
        padding-top: 10px;

        font-size: 16px;
        line-height: 24px;
        word-break: break-all;
      }
    }
  }
  .not-found {
    font-size: 16px;
    color: #333;
    padding: 20px 0;
  }
  .detail-frame {
    width: 100%;
    min-height: 500px;
    padding-top: 10px;

    font-size: 16px;
    line-height: 24px;
    word-break: break-all;
  }
</style>
